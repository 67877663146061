import React from "react"
import { graphql, Link } from "gatsby"
import { Grid, Box, IconButton } from "theme-ui"
import { css } from "styled-components"
// import app components
import { Edges, Post } from "components"
// import Post from "components/Post"

const Template = props => {
  const {
    data: {
      wpPage: { title },
      allWpPost: { nodes: posts },
    },
    pageContext: {
      pagination: { basePath, page, numberOfPages, postsPerPage },
    },
  } = props

  const renderPagination = () => {
    const items = []

    for (let i = 1; i <= numberOfPages; i++) {
      let pathname = basePath

      if (i > 1) {
        pathname = `${basePath}page/${i}`
      }

      items.push(
        <Link key={i} to={pathname} style={{ textDecoration: "none" }}>
          <IconButton children={`${i}`} variant={i === page && "primary"} />
        </Link>
      )
    }

    return items
  }

  return (
    <Box pt={5} pb={5}>
      <div
        css={css`
          padding-top: 120px;
        `}
      />

      <Edges size="md">
        {title && <h1>{title}</h1>}

        <Grid gap={2} columns={[1, 2]}>
          {posts &&
            posts
              .slice(
                (page - 1) * postsPerPage,
                (page - 1) * postsPerPage + postsPerPage
              )
              .map(o => (
                <Post
                  key={o.id}
                  image={o.acf.image}
                  headline={o.title}
                  url={o.uri}
                />
              ))}
        </Grid>

        <Box pt={5} pb={5}>
          <Grid gap={2} columns={[20]}>
            {renderPagination()}
          </Grid>
        </Box>
      </Edges>
    </Box>
  )
}

export const Query = graphql`
  query PostArchive($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      title
      seo {
        title
        metaDesc
        opengraphImage {
          sourceUrl
        }
      }
    }
    allWpPost {
      nodes {
        id
        title
        uri
        acf {
          image {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Template
